import React from 'react';
import { RouterProvider } from 'react-router-dom';

import router from './router';

/**
 * The base Router for QNG
 */
export function AppRouter() {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <>
      {showDevtools && (
        <React.Suspense fallback={false}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      )}
      <RouterProvider router={router} />
    </>
  );
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);
