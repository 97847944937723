import { useCookies } from 'react-cookie';

import { TranslationComponentProps } from '@/types/translation';
import { QNG_COOKIE_MAX_AGE, QNG_COOKIE_NAME } from '@/utils/constants';

import { CookiePolicyOverlayView } from './cookie-policy-overlay-view';

type CookiePolicyOverlayDataProps = {
  persistCookieName?: string;
} & TranslationComponentProps;

export function CookiePolicyOverlayData({
  persistCookieName = QNG_COOKIE_NAME,
  t,
}: CookiePolicyOverlayDataProps) {
  const [cookies, setCookie] = useCookies([persistCookieName], {
    doNotParse: true,
  });

  const cookieSet = cookies[persistCookieName] !== undefined;
  const cookiesAllowed = cookies[persistCookieName] === 'true';

  return (
    <CookiePolicyOverlayView
      showOverlay={!cookieSet}
      cookiesAllowed={cookiesAllowed}
      setCookiesAllowed={(val) =>
        setCookie(persistCookieName, val, { maxAge: QNG_COOKIE_MAX_AGE })
      }
      t={t}
    />
  );
}
