import { AlertDialogTrigger, Button, SheetTrigger } from '@pebl/ui';
import clsx from 'clsx';
import React from 'react';

import { ApiResourceAction, ApiResourceActionColor } from '@/api/types';

import { mapActionVariantToButton } from '../map-action-variant-to-button';

type ActionListButtonProps = {
  action: ApiResourceAction;
  activeInstantAction: ApiResourceAction | undefined;
  setSelectedAction: (action: ApiResourceAction | undefined) => void;
  isPending: boolean;
  wrapperType: 'dialog' | 'sheet';
};

/**
 * ⚠️ DO NOT USE THIS COMPONENT DIRECTLY - THIS IS AN INTERNAL COMPONENT
 */
export const ActionListButton = React.forwardRef<
  HTMLButtonElement,
  ActionListButtonProps
>(function ActionListButton(
  {
    action,
    activeInstantAction,
    isPending,
    setSelectedAction,
    wrapperType,
  }: ActionListButtonProps,
  ref,
) {
  const isLoading = isPending && action.key === activeInstantAction?.key;
  const isDisabled = action.disabled || isLoading;

  const ListWrapperType =
    wrapperType === 'dialog' ? AlertDialogTrigger : SheetTrigger;
  const buttonVariant = mapActionVariantToButton(action.variant);

  if (!buttonVariant) {
    return false;
  }

  return (
    <ListWrapperType asChild ref={ref}>
      <Button
        className={clsx(
          'qng-action-list-item',
          `
            qng-action-list-item-${action.type}
          `,
          mapButtonColourToClasses(action.color),
          'w-full',
        )}
        onClick={() => setSelectedAction(action)}
        variant={buttonVariant}
        disabled={isDisabled}
        isLoading={isLoading}
        loadingText={action.text}
      >
        {action.text}
      </Button>
    </ListWrapperType>
  );
});

function mapButtonColourToClasses(
  colour?: ApiResourceActionColor | undefined,
): string | undefined {
  if (!colour) {
    return undefined;
  }

  /*
   * NOTE: We don't do anything here and we likely shouldn't, as it's
   *       up to UDS + PEBL to define stuff like this, but this is here
   *       should we need to do something in the future.
   */

  return undefined;
}
