import React from 'react';
import { useParams } from 'react-router-dom';

import { AnimatedRoute } from '../animated-route';

const ActionListLoader = React.lazy(
  () => import('@/features/actions/components/action-list-loader'),
);

type TagRouteParams = {
  tagId: string;
};

export function TagRoute() {
  const { tagId } = useParams<TagRouteParams>();

  if (!tagId) {
    // TODO: Assess how we actually want to handle this
    throw new Error('Missing required route params');
  }

  return (
    <AnimatedRoute animationKey="sign-in">
      <div className="page-container">
        <ActionListLoader tagId={tagId} />
      </div>
    </AnimatedRoute>
  );
}
