import { useSelectQngAuth } from '@/stores/selectors';
import { TranslationComponentProps } from '@/types/translation';

import { SideBarView } from './side-bar-view';

type SideBarDataProps = {} & TranslationComponentProps;

export function SideBarData({ t }: SideBarDataProps) {
  const { authToken, isAuthTokenAnonymous } = useSelectQngAuth();

  const isUserSignedIn = !!authToken && !isAuthTokenAnonymous;

  return <SideBarView isUserSignedIn={isUserSignedIn} t={t} />;
}
