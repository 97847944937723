import { AuthorizedApiOptions, CancellableApiOptions } from '@/api/types';
import { ResourceQueryKeyFn } from '@/types/invalidation';

import { axios } from '../../axios';
import { getCommonHeaders } from '../../utils/headers';
import { ApiRootResponse } from './types';

const ENDPOINT = '';

export const resourceQueryKey: ResourceQueryKeyFn = () => ['root']; // TODO: Confirm this is the query key we use

export async function getRoot(
  options: CancellableApiOptions & AuthorizedApiOptions = {},
): Promise<ApiRootResponse> {
  const { data } = await axios.get(ENDPOINT, {
    signal: options?.signal,
    headers: getCommonHeaders({ authToken: options.authToken }),
  });

  return data;
}
