import { motion, Variants } from 'framer-motion';
import React from 'react';

type AnimatedRouteProps = {
  animationKey: string;
  variants?: Variants;
  initial?: string;
  enter?: string;
  exit?: string;
};

/**
 * A wrapper component that can be used to provide "page" entry/exit
 * transitions for our routes.
 * Simply wrap your route with `<AnimatedRoute>...</AnimatedRoute>` and it will animate
 * in and out when the route changes.
 *
 * The default setup (Variants) are setup for us to have a consistent
 * animation across all uses, however I have set this up so you can provide
 * your own variants if you want to customise the animation for a specific route.
 */
export function AnimatedRoute({
  animationKey,
  children,
  enter = 'enter',
  exit = 'exit',
  initial = 'initial',
  variants = {
    initial: {
      x: '100%',
    },
    enter: {
      x: 0,
      transition: {
        type: 'spring',
        mass: 0.4,
      },
    },
    exit: {
      x: '-100%',
      transition: {
        type: 'tween',
        duration: 0.15,
      },
    },
  },
}: React.PropsWithChildren<AnimatedRouteProps>) {
  return (
    <motion.div
      key={animationKey}
      variants={variants}
      initial={initial}
      animate={enter}
      exit={exit}
      className="relative"
    >
      {children}
    </motion.div>
  );
}
