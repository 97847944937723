import { AxiosError, isAxiosError } from 'axios';

import router from '@/entrypoints/guest-website/router';
import RoutePathConstants from '@/entrypoints/guest-website/routing/route-path-constants';
import log from '@/utils/logging';

type UnauthorizedHandlerParamType = AxiosError | unknown;

type UnauthorizedHandlerResponseType = AxiosError | unknown;

/**
 * This is our "middleware" which can be invoked via an Axios
 * interceptor when a response error occurs.
 * This allows us to handle unauthorized responses globally.
 */
export function unauthorizedErrorMiddleware(
  error: UnauthorizedHandlerParamType,
): UnauthorizedHandlerResponseType {
  if (isAxiosError(error)) {
    // Handle unauthorized responses here
    if (error.response?.status === 401) {
      /*
       * The simplest way to handle this is just redirect the user to the
       * sign out page, rather than trying to get fiddly with using the
       * store here and signing out the user.
       */
      log.warn('Request responded unauthorized (401) - Signing out');
      log.debug(
        'Navigating to signout path',
        RoutePathConstants.SIGN_OUT.link(),
      );
      router.navigate(RoutePathConstants.SIGN_OUT.link());

      /*
       * The alternative to navigating to the sign out page and letting
       * it handle the sign out process is to do it here but that would
       * require direct access to Zustand here e.g.
       * setAuthToken({ authToken: undefined, isTokenAnonymous: true });
       */

      // TODO: Can we consume this error somehow to stop the rest of the site freaking out
    }
  }

  return error;
}
