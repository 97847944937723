import { Typography } from '@pebl/ui';
import { useCallback, useState } from 'react';

import { ApiResourceActionOptionsPropertyItem } from '@/api/types';

import { MultiselectViewItem } from './multiselect-view-item';

export type ActionPropertyMultiselectViewProps = {
  items: ApiResourceActionOptionsPropertyItem[];
  onChange?: (value?: ApiResourceActionOptionsPropertyItem[]) => void;
  error?: string;
};

export type ActionPropertyMultiselectItemData = {
  text?: string;
  descriptions?: string[];
  data?: string;
  value?: number;
  defaultItem?: boolean;
  thumbnail?: string;
  isSelected: boolean;
};

export function ActionPropertyMultiselectView({
  error,
  items,
  onChange,
}: ActionPropertyMultiselectViewProps) {
  const [listItems, setListItems] = useState<
    ActionPropertyMultiselectItemData[]
  >(items.map((x) => ({ ...x, isSelected: !!x.default })));

  const toggleSelected = useCallback(
    (item: string) => {
      // Update selected state
      const newItems = listItems.map((x) =>
        x.data !== item ? x : { ...x, isSelected: !x.isSelected },
      );
      setListItems(newItems);

      // Update controller with new selected items
      const selectedItemData = newItems.filter((x) => x.isSelected);
      onChange?.(selectedItemData.length ? selectedItemData : undefined);
    },
    [listItems, onChange],
  );

  return (
    <div className="action-property-multiselect flex flex-col gap-4">
      {listItems.map((item) => (
        <MultiselectViewItem
          key={item.data}
          data={item.data}
          text={item.text}
          value={item.value}
          default={item.defaultItem}
          descriptions={item.descriptions}
          thumbnail={item.thumbnail}
          isSelected={item.isSelected}
          toggleSelected={() => item.data && toggleSelected(item.data)}
        />
      ))}
      {error && (
        <Typography variant="body2" className="text-red-500">
          {error}
        </Typography>
      )}
    </div>
  );
}
