import { useEffect, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { signOut } from '@/stores/qng-data-store';
import log from '@/utils/logging';

import RoutePathConstants from '../route-path-constants';

export function SignOutRoute() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();

  /**
   * NOTE: This is wrapped in / uses a transition to avoid causing
   *       flickering or warnings/errors by error boundaries while dealing
   *       with the error and redirecting the user.
   */
  useEffect(() => {
    startTransition(() => {
      log.info('🚪Signing out current user');
      signOut();
      navigate(RoutePathConstants.HOME.link(), { replace: true });
    });
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPending) {
    return <div>{t('signing_out')}</div>;
  }

  return false;
}
