import React from 'react';

import { AnimatedRoute } from '../animated-route';

const ActionListLoader = React.lazy(
  () => import('@/features/actions/components/action-list-loader'),
);

/**
 * This is a de-coupling between the route and route param handling
 * and the underlying component(s) that are rendered.
 *
 * This component mostly exists just to be used in the route definition
 * and then extract & validate any route params that are needed by the
 * underlying component(s).
 *
 * This means the underlying components have NO concept of routing or
 * route params, and can be more easily used elsewhere as they just
 * expect props.
 *
 * This specific route isn't a great example as currently it has no
 * params etc, but it's more about being consistent with this pattern.
 */
export function ChangeLanguageRoute() {
  return (
    <AnimatedRoute animationKey="change-language">
      <div className="page-container">
        <ActionListLoader tagId="navigation.language" />
      </div>
    </AnimatedRoute>
  );
}

// /change_language
