import { Controller, useFormContext } from 'react-hook-form';

import { ApiResourceActionOptionsProperty } from '@/api/types';

import { mapActionOptionsPropertyToRules } from '../../../../utils/map-action-property-rules';
import { CommonActionPropertyOptions } from '../../types';
import { ActionPropertyMultiselectView } from './multiselect-view';

type ActionPropertyMultiselectProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyMultiselect({
  data,
  id,
  options,
}: ActionPropertyMultiselectProps) {
  const { errorMessage, required } = options;
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      rules={mapActionOptionsPropertyToRules(data, required)}
      render={({ field }) => (
        <ActionPropertyMultiselectView
          items={data?.enum ?? []}
          // We can't just spread field here due to ref problems
          onChange={field.onChange}
          error={errorMessage}
        />
      )}
      control={control}
    />
  );
}
