export default {
  en: {
    translation: {
      account: {
        activate: {
          subtitle: '{{count}} passes available',
          title: 'Activate my THE FLASH Pass to use today',
        },
        claim: {
          membership: 'Activate Membership Benefits',
          ticket: 'Activate THE FLASH Pass',
          username: 'Find my purchases',
        },
        error: {
          loading_description: 'Error loading account page',
          reset: 'Try again',
        },
        headers: {
          account: 'Account settings',
          card: 'Manage payment methods',
          other: 'General',
        },
        not_signed_in: 'Not signed in',
        title: 'My Account',
      },
      actions: {
        'action-form-loader': {
          error: {
            reset: 'Try again',
          },
        },
        'action-list-loader': {
          error: {
            reset: 'Try again',
          },
        },
        default_submit_button: 'Submit',
        default_submit_button_pending: 'Submitting...',
        stepped: {
          invalid_step: 'Invalid step',
          next: 'Next',
          prev: 'Previous',
          submit: 'Submit',
        },
        unknown_error: 'An unknown error occurred',
      },
      attractions_details: {
        error: {
          loading_description: 'Error loading attraction details',
          reset: 'Try again',
        },
      },
      attractions_list: {
        error: {
          loading_description: 'Error loading attractions list',
          reset: 'Try again',
        },
        header: 'All rides',
        item: {
          image: {
            alt_text: 'Image of {{name}}',
          },
        },
      },
      basket: {
        basket_details: {
          edit: 'Edit',
          perPerson: 'per person',
        },
        basket_invoice: {
          subtotal: 'Subtotal',
          tax: 'Tax',
          total: 'Total',
        },
        expired:
          'Your cart has expired. Please check the contents and try again',
        expiring: 'Your cart will expire in {{minsValue}} minutes',
        start_shopping_button: 'Start shopping',
      },
      common: {
        back: 'Back',
        confirm: 'Ok',
        default_error_title: 'Error',
        dialog: {
          close_button: 'Close',
        },
        error: {
          primaryDescription: 'Whoops!',
          reset: 'Try again',
          secondaryDescription: 'Something went wrong',
        },
      },
      cookie_policy: {
        accept_button: 'Accept All',
        close: 'Close',
        consent: 'Do you consent to our Cookie Policy?',
        cookie_policypage: {
          error: {
            loading_description: 'Error loading cookie policy page',
            reset: 'Try again',
          },
        },
        description:
          "Our site needs to use functional cookies to ensure that you have the best experience possible. By continuing to browse the site you consent to having cookies placed on your device. Don't worry though, these are harmless and ensure we can keep a track of your reserved rides and continue to provide an excellent service to you. Not allowing cookies on this website means you won't be able to use the system. <br><br> We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf.",
        error: {
          loading_description: 'There was an error loading the cookie policy!',
          reset: 'Try again',
        },
        link: 'Click here to see more',
        optional_cookies_description:
          'We use tracking cookies to better understand our users’ needs and to optimize this service and experience.',
        optional_cookies_title: 'Optional cookies',
        reject_button: 'Reject Optional',
        required_cookies_description:
          "Our site needs to use functional cookies to ensure that we can keep a track of your reserved rides and continue to provide an excellent service to you. Not allowing cookies on this website means you won't be able to use the system.",
        required_cookies_title: 'Functional cookies',
        title: 'Cookie Policy',
      },
      developer: {
        error: {
          loading_description: 'Error loading Developer page',
          reset: 'Try again',
        },
        heading: 'Developer',
        tabs: {
          settings: {
            description:
              'This is a list of all the settings provided for this deployment from the /settings.json endpoint and any theme settings.',
          },
        },
      },
      flows: {
        entitlement_claiming: {
          error: {
            loading_description: 'Unable to load entitlement claiming flow',
            reset: 'Try again',
          },
          membership: {
            activate: {
              success: {
                confirm: 'Got it',
                description:
                  'You are ready to ride! Select a ride to create a reservation and start using your THE FLASH Pass',
                title: 'Success! Your THE FLASH Pass is activated',
              },
            },
            claim: {
              activate_now: 'Activate now',
              existing_entitlements: {
                cancel: 'Look up additional passes',
                confirm: 'Use these purchases now',
                title: 'We found purchases associated with your account!',
              },
              new_entitlements: {
                cancel: 'Add another',
                confirm: 'Start riding now',
                title: 'Added to account!',
              },
              no_action: 'No claim action available',
            },
          },
          ticket: {
            activate: {
              success: {
                confirm: 'Got it',
                description:
                  'You are ready to ride! Select a ride to create a reservation and start using your THE FLASH Pass',
                title: 'Success! Your THE FLASH Pass is activated',
              },
            },
            claim: {
              activate_now: 'Activate now',
              existing_entitlements: {
                cancel: 'Look up additional passes',
                confirm: 'Use these purchases now',
                title: 'We found purchases associated with your account!',
              },
              new_entitlements: {
                cancel: 'Add another',
                confirm: 'Start riding now',
                title: 'Added to account!',
              },
              no_action: 'No claim action available',
            },
          },
          username: {
            activate: {
              success: {
                confirm: 'Got it',
                description:
                  'You are ready to ride! Select a ride to create a reservation and start using your THE FLASH Pass',
                title: 'Success! Your THE FLASH Pass is activated',
              },
            },
            claim: {
              activate_now: 'Activate now',
              existing_entitlements: {
                cancel: 'Look up additional passes',
                confirm: 'Use these purchases now',
                title: 'We found purchases associated with your account!',
              },
              new_entitlements: {
                cancel: 'Add another',
                confirm: 'Start riding now',
                title: 'Added to account!',
              },
              no_action: 'No claim action available',
            },
          },
        },
      },
      headerbar: {
        error: {
          loading_description: 'Error loading header bar',
          reset: 'Try again',
        },
      },
      home: {
        error: {
          reset: 'Try again',
        },
      },
      not_found_error: {
        description: 'Not Found',
        header: '404',
      },
      onboarding_overlay: {
        error: {
          loading_description: 'Error loading onboarding overlay',
          reset: 'Try again',
        },
        membership: {
          primary: 'Use my pass or benefits',
          secondary: 'Activate Season Pass or Membership Benefits',
        },
        shop: {
          primary: 'Buy THE FLASH PASS',
          secondary: 'I want to buy THE FLASH PASS',
        },
        ticket: {
          primary: 'Use my THE FLASH PASS',
          secondary: 'Already purchased? Activate here',
        },
        title: 'Start using THE FLASH PASS!',
      },
      onboarding_tile: {
        error: {
          primaryDescription: 'Whoops! Something went wrong',
          reset: 'Try again',
        },
      },
      reservations: {
        error: {
          primaryDescription: 'Whoops, Something went wrong',
          reset: 'Try again',
          secondaryDescription:
            'Click to try again, alternatively please contact a team member for assistance',
        },
        reservation_barcode: 'reservation barcode',
      },
      session_tile: {
        error: {
          reset: 'Try again',
          subtitle: 'Please retry or see guest services',
          title: 'Error loading your reservations',
        },
      },
      shop_drawer: {
        error: {
          primaryDescription: 'Error loading shop drawer',
          reset: 'Try again',
          secondaryDescription: '',
        },
      },
      shop_per_person: 'per person',
      sidebar: {
        account: 'My Account',
        attractions: 'Attractions',
        change_language: 'Change language',
        claim_membership: 'Activate Membership Benefits',
        claim_tickets: 'Activate THE FLASH PASS',
        cookie_policy: 'Cookie policy',
        error: {
          loading_description: 'Error loading sidebar',
          reset: 'Try again',
          shop: {
            reset: 'Try again',
          },
        },
        get_the_app: 'Six Flags App',
        get_the_app_link: 'https://www.sixflags.com/national/app-download',
        how_to_use: 'How to use',
        how_to_use_link: 'https://howtouse.sfflashpass.com/howto.png',
        shop: 'Shop',
        welcome_anonymous: 'Welcome',
      },
      sidebar_welcome_anonymous: 'Welcome',
      sign_in: 'Sign in',
      sign_out: 'Sign out',
      signing_out: 'Signing out...',
      sitebanner: {
        error: {
          loading_description: 'Error loading site banner',
          reset: 'Try again',
        },
      },
    },
  },
};
