import * as log from 'loglevel';

if (import.meta.env.DEV) {
  log.setDefaultLevel('DEBUG');
} else {
  log.setDefaultLevel('INFO');
}

export const setLogLevel = (level: log.LogLevelDesc, persist?: boolean) => {
  console.log(`Setting log level to ${level}`);
  log.setLevel(level, persist);
};

// Expose a helper on 'window' so devs can alter log level on prod sites
window.setLogLevel = setLogLevel;

export default log;
