import { ApiActionMethod } from '../types';

export function mapApiActionMethodToHttpMethod(
  method: ApiActionMethod,
): 'POST' | 'GET' {
  switch (method) {
    case 'post':
      return 'POST';
    case 'get':
      return 'GET';
    default: // TODO: Do we want to throw here?
      return 'GET';
  }
}
