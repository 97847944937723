import { Label, QuantityStepper, Typography } from '@pebl/ui';
import clsx from 'clsx';

import { CommonActionPropertyViewProps } from '../../types';

export function ActionPropertyQuantityStepperView({
  disabled,
  error,
  // helperText,
  helperText,
  initialValue,
  innerRef,
  label,
  max,
  min,
  name,
  onBlur,
  onChange,
  value,
}: {
  error?: boolean;
  label?: string;
  helperText?: string;
  min?: number;
  max?: number;
  initialValue?: number;
} & CommonActionPropertyViewProps<number>) {
  return (
    <div className="qng-action-property-input-spinner flex flex-col items-center gap-2">
      <Label
        htmlFor={name}
        className={`
          qng-action-property-input-spinner-label font-semibold

          ${error ? 'text-red-500' : ''}
        `}
      >
        {label}
      </Label>
      <QuantityStepper
        id={name}
        name={name}
        quantity={value ?? initialValue ?? min ?? 0}
        min={min}
        max={max}
        disabled={disabled}
        onChange={(val) => onChange?.(val)}
        onBlur={onBlur}
        className="qng-action-property-input-spinner-input"
        ref={innerRef}
        // Data Fields - Primarily for debugging / testing
        data-action-option-type="number"
        data-action-option-format="inputspinner"
      />
      {helperText && (
        <Typography
          variant="body2"
          className={clsx(
            'qng-action-property-input-spinner-helper-text',
            error ? 'text-red-500' : '',
          )}
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
}
