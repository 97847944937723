import React from 'react';
import { useParams } from 'react-router-dom';

import { AnimatedRoute } from '../animated-route';

const AttractionDetails = React.lazy(
  () => import('@/features/attractions/components/attraction-details'),
);

type AttractionDetailsRouteParams = {
  attractionId: string;
};

/**
 * This is a de-coupling between the route and route param handling
 * and the underlying component(s) that are rendered.
 *
 * This component mostly exists just to be used in the route definition
 * and then extract & validate any route params that are needed by the
 * underlying component(s).
 *
 * This means the underlying components have NO concept of routing or
 * route params, and can be more easily used elsewhere as they just
 * expect props.
 */
export function AttractionDetailsRoute() {
  const { attractionId } = useParams<AttractionDetailsRouteParams>();

  if (!attractionId) {
    // TODO: Assess how we actually want to handle this
    throw new Error('Missing required route params');
  }

  return (
    <AnimatedRoute animationKey="attraction-details">
      <AttractionDetails attractionId={attractionId} />
    </AnimatedRoute>
  );
}
