/* eslint-disable react-compiler/react-compiler */
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ShopItemTypes } from '@/types/shop';
import { isWithinUnion } from '@/utils/typeguards';

import { AnimatedRoute } from '../animated-route';

const Shop = React.lazy(() => import('@/features/shop/components/shop'));

/**
 * This is a de-coupling between the route and route param handling
 * and the underlying component(s) that are rendered.
 *
 * This component mostly exists just to be used in the route definition
 * and then extract & validate any route params that are needed by the
 * underlying component(s).
 *
 * This means the underlying components have NO concept of routing or
 * route params, and can be more easily used elsewhere as they just
 * expect props.
 */
export function ShopRoute() {
  const { type } = useParams();
  const navigate = useNavigate();

  const shopType = useRef<(typeof ShopItemTypes)[number] | undefined>();

  useEffect(() => {
    if (
      isWithinUnion<(typeof ShopItemTypes)[number]>(type, [...ShopItemTypes])
    ) {
      shopType.current = type;
    } else {
      shopType.current = 'service';
      navigate(`/shop/${shopType.current}`, { replace: true });
    }
  }, [navigate, type]);

  return (
    <AnimatedRoute animationKey="shop">
      {/* TODO: useParams is not working, adding 'service' fallback for now (not sure if merge error or just needs fixing) */}
      <Shop type={shopType.current ?? 'service'} />
    </AnimatedRoute>
  );
}
