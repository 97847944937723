import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { CookiePolicyOverlayData } from './cookie-policy-overlay-data';
import { CookiePolicyOverlayError } from './cookie-policy-overlay-error';
import { CookiePolicyOverlayView } from './cookie-policy-overlay-view';

export default function CookiePolicyOverlay() {
  const { t } = useTranslation();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={(p) => <CookiePolicyOverlayError {...p} t={t} />}
        >
          <Suspense fallback={<CookiePolicyOverlayView loading t={t} />}>
            <CookiePolicyOverlayData t={t} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
