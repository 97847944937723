import { Typography, WarningStatusIcon } from '@pebl/ui';
import { useTranslation } from 'react-i18next';

import { TranslationComponentProps } from '@/types/translation';

type Error404Props = {} & Partial<TranslationComponentProps>;

export function Error404({ t }: Error404Props) {
  /**
   * This is NOT the pattern we normally use for handling translations,
   * however due to how this component gets used in the routing setup
   * and my desire to not completely break/refactor all of that code,
   * this has the `useTranslation` within the component (as a fallback)
   * rather than ONLY accepting it in as a prop.
   */
  const { t: internalT } = useTranslation();
  const translate = t ?? internalT;

  return (
    <div className="qng-error-404 max-w-full pt-4 text-center">
      <WarningStatusIcon className="qng-error-404-icon mx-auto size-16" />
      <Typography
        variant="h1"
        className="qng-error-404-header overflow-hidden text-ellipsis"
      >
        {translate('not_found_error.header')}
      </Typography>
      <Typography
        variant="h2"
        className="qng-error-404-description overflow-hidden text-ellipsis"
      >
        {translate('not_found_error.description')}
      </Typography>
    </div>
  );
}
