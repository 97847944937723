import { Skeleton, Typography } from '@pebl/ui';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { forwardRef } from 'react';

import { ApiResourceAction } from '@/api/types/actions';
import { CommonViewProps } from '@/types/core';
import { TranslationComponentProps } from '@/types/translation';

import { ActionFormCallbacks } from '../../types';
import {
  ActionFormBuilder,
  ActionFormBuilderRef,
} from '../action-form-builder';

type ActionFormViewProps = {
  action?: ApiResourceAction;

  /**
   * An optional parameter that can be set to prevent any redirection
   * handling the API may include in the response from being handled
   * higher up the stack (up in the API level).
   * Generally we wouldn't want to prevent this, but there may be
   * cases where we need to.
   */
  preventRedirect?: boolean;

  /**
   * If `true`, the title of the action will not be shown in the form.
   * @default false - The title will be displayed.
   */
  hideTitle?: boolean;

  /**
   * A callback for the form to call to execute the action.
   * @param formData - The form data to submit to the action.
   */
  submitAction?: (formData: unknown) => void;

  /**
   * If `true`, the form is in a pending state.
   */
  isPending?: boolean;

  /**
   * Optionally provide class names to add to the ActionForm container.
   */
  className?: string;
} & TranslationComponentProps &
  Pick<ActionFormCallbacks, 'onActionCancelled'> &
  CommonViewProps;

export const ActionFormView = forwardRef<
  ActionFormBuilderRef,
  ActionFormViewProps
>(function ActionFormView(
  {
    action,
    className,
    hideTitle,
    isPending,
    loading,
    onActionCancelled,
    submitAction,
  }: ActionFormViewProps,
  ref,
) {
  return (
    <div className={clsx('qng-action-form flex flex-col gap-4', className)}>
      {!hideTitle && (loading || action?.title) && (
        <Typography
          variant="h1"
          className="qng-action-form-title text-xl font-medium"
        >
          {loading ? (
            <Skeleton variant="text" className="mb-4 h-12" />
          ) : (
            (action?.title ?? '')
          )}
        </Typography>
      )}
      {action?.description && (
        <Typography variant="body1" className="qng-action-form-description">
          {loading ? (
            <Skeleton variant="text" className="mb-4 h-4" />
          ) : (
            parse(action?.description ?? '')
          )}
        </Typography>
      )}
      {/* 
        WE have no way of knowing what shape an action form could be ahead of 
        time, so forgo any skeleton loading here for now as it may just be
        more confusing.
      */}
      {!loading && (
        <ActionFormBuilder
          ref={ref}
          action={action}
          submitAction={submitAction}
          isPending={isPending}
          onActionCancelled={onActionCancelled}
        />
      )}
    </div>
  );
});
