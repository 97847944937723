import { Button } from '@pebl/ui';
import { FallbackProps } from 'react-error-boundary';

import { TranslationComponentProps } from '@/types/translation';

export function CookiePolicyOverlayError({
  resetErrorBoundary,
  t,
}: FallbackProps & TranslationComponentProps) {
  return (
    <div className="qng-cookie-policy-overlay-error grid grid-rows-2">
      <p>{t('cookie_policy.error.loading_description')}</p>
      <Button onClick={() => resetErrorBoundary()}>
        {t('cookie_policy.error.reset')}
      </Button>
    </div>
  );
}
