import { Checkbox } from '@pebl/ui';

import { CommonActionPropertyViewProps } from '../../types';

/**
 * TODO: This requires support for being in an error state / showing an error message
 * BUG: Space keybinding doesn't work to toggle the checkbox
 */
export function ActionPropertyCheckboxView({
  defaultValue,
  // error,
  disabled,
  innerRef,
  label,
  name,
  onBlur,
  onChange,
  value,
}: {
  error?: boolean;
  label?: string;
} & CommonActionPropertyViewProps<boolean>) {
  return (
    <Checkbox
      id={name}
      label={label}
      // error={error}
      name={name}
      disabled={disabled}
      checked={value}
      defaultChecked={defaultValue}
      onCheckedChange={(checked) => onChange?.(!!checked)}
      onBlur={onBlur}
      className="qng-action-property-input-checkbox"
      ref={innerRef}
      // Data Fields - Primarily for debugging / testing
      data-action-option-type="boolean"
      data-action-option-format="checkbox"
    />
  );
}
