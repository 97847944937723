import { Button, RefreshIcon } from '@pebl/ui';
import { FallbackProps } from 'react-error-boundary';

import { TranslationComponentProps } from '@/types/translation';

export function HeaderBarError({
  resetErrorBoundary,
  t,
}: FallbackProps & TranslationComponentProps) {
  return (
    <div className="qng-header-bar-error flex flex-row items-center">
      <p className="grow">{t('headerbar.error.loading_description')}</p>
      <Button
        onClick={() => resetErrorBoundary()}
        className="grid grid-cols-[max-content,1fr] items-center gap-2"
      >
        <RefreshIcon className="size-6" />
        {t('headerbar.error.reset')}
      </Button>
    </div>
  );
}
