import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Typography,
} from '@pebl/ui';
import clsx from 'clsx';

import { CommonActionPropertyViewProps } from '../../types';

export type ActionPropertyLanguageListItemData = {
  value: string;
  displayText?: string;
};

export function ActionPropertyLanguageListView({
  defaultValue,
  disabled,
  error,
  helperText,
  innerRef,
  items,
  label,
  name,
  onChange,
  placeholder,
  required,
  value,
}: {
  error?: boolean;
  label?: string;
  placeholder?: string;
  helperText?: string;
  items: ActionPropertyLanguageListItemData[];
} & CommonActionPropertyViewProps<string>) {
  return (
    <div className="qng-action-property-language-list flex flex-col">
      <Select
        value={value}
        defaultValue={defaultValue}
        onValueChange={onChange}
        name={name}
        required={required}
        disabled={disabled}
        // Data Fields - Primarily for debugging / testing
        data-action-option-type="object"
        data-action-option-format="select"
      >
        <SelectTrigger
          placeholder={placeholder}
          label={label}
          className="qng-action-property-language-list-trigger"
          ref={innerRef}
          error={error}
        />
        <SelectContent className="qng-action-property-language-list">
          {items.map((item) => (
            <SelectItem
              key={item.value}
              value={item.value}
              displayText={item.displayText ?? item.value}
            />
          ))}
        </SelectContent>
      </Select>
      {helperText && (
        <Typography
          variant="caption"
          className={clsx(error ? 'text-red-500' : undefined)}
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
}
