import Payment from '@/features/shop/components/payment';

import { AnimatedRoute } from '../animated-route';

export function PaymentRoute() {
  return (
    <AnimatedRoute animationKey="payment">
      <Payment />
    </AnimatedRoute>
  );
}
