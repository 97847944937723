import { ApiResourceActionVariant } from '@/api/types';

type PeblButtonVariant = 'default' | 'outline' | 'text';

/**
 * The API provides a variant for the action button, but the Button component
 * from the UI library uses a different set of variants. This function maps
 * the API variant to the Button variant.
 *
 * @param actionVariant - The variant of the action button from the API.
 * @returns - The variant of the Button component.
 */
export function mapActionVariantToButton(
  actionVariant?: ApiResourceActionVariant | undefined,
): PeblButtonVariant | undefined {
  switch (actionVariant) {
    case 'filled':
      return 'default';
    case 'outlined':
      return 'outline';
    case 'text':
      return 'text';
    case 'apple':
    case 'google':
    case 'facebook':
      return undefined;
    default:
      return 'default';
  }
}
