import { createHashRouter } from 'react-router-dom';

import { Error404 } from '@/components/error-404';

import { Layout } from './layout';
import routes from './routing';

// const router = createBrowserRouter([
const router = createHashRouter([
  {
    element: <Layout />,
    errorElement: <Error404 />,
    children: routes,
  },
]);

export default router;
