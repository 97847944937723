import { useEffect, useMemo, useState } from 'react';

/**
 * It's very unlikely you should use or need to use this hook!
 * It's an internal hook for the dynamic theming and localisation, but
 * since some of the functionality of those is shared it has been
 * extracted to a hook.
 *
 * If a deploy configured theme is set then this will return the theme path
 * always with a trailing slash.
 *
 * If no theme is set then it will return `undefined`.
 */
type useConfigThemePathProps = {
  localStorageKey?: string;
  overrideTheme?: string;
};

export default function useConfigThemePath({
  localStorageKey = 'qng-theme-override',
  overrideTheme,
}: useConfigThemePathProps) {
  // const defaultTheme = (window?.globalQngConfig ?? {}).defaultTheme;
  const [value, setValue] = useState<string | undefined>(undefined);

  /*
   * Setup a watcher on window.globalQngConfig to update this
   * when it changes? I.E. slow load ?
   */
  useEffect(() => {
    function checkQngConfigChange() {
      const newTheme = (window?.globalQngConfig ?? {}).defaultTheme;
      if (newTheme !== value) {
        setValue(newTheme);
      }
    }

    checkQngConfigChange();
    window.addEventListener('globalQngConfig', checkQngConfigChange);

    return () => {
      window.removeEventListener('globalQngConfig', checkQngConfigChange);
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * `theme` will be the value provided by the deployment config.
   * This should be a path reference within the theme repository.
   * E.G.
   * `sixflags`
   * `ruscombepark/xmas`
   */
  const theme = useMemo(() => {
    const localStorageOverride = localStorageKey
      ? window.localStorage.getItem(localStorageKey)
      : undefined;

    const val = localStorageOverride ?? overrideTheme ?? value;
    if (!val) {
      return undefined;
    }

    // Ensure val ends with a slash
    return val.endsWith('/') ? val : `${val}/`;
  }, [value, localStorageKey, overrideTheme]);

  return theme;
}
