import { Button, CartIcon, SyncIcon } from '@pebl/ui';
import { FallbackProps } from 'react-error-boundary';

import { TranslationComponentProps } from '@/types/translation';

export function ShopItemError({
  resetErrorBoundary,
  t,
}: FallbackProps & TranslationComponentProps) {
  return (
    <div className="grid grid-cols-[max-content,1fr] items-center gap-4">
      <CartIcon className="size-6" />
      <Button
        onClick={() => resetErrorBoundary?.()}
        className="flex h-8 p-2 pt-1"
      >
        <div className="flex items-center gap-1">
          <SyncIcon className="size-4" /> {t('sidebar.error.shop.reset')}
        </div>
      </Button>
    </div>
  );
}
