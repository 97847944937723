import { ApiResourceAction } from '@/api/types';

/**
 * Determine if an ApiResourceAction is an "Instant Action" and
 * should be executed immediately (when clicked).
 *
 * Essentially this checks if the action has any options/properties
 * and has a valid href to execute against.
 *
 * This is here to centralise this behaviour and avoid duplicating it.
 *
 * @param action - The action to check
 * @returns True if the action is an instant action, false otherwise
 */
export function isInstantAction(
  action: ApiResourceAction | undefined,
): boolean {
  if (!action) {
    return false;
  }

  const actionOptionsCount = Object.keys(action.options ?? {}).length;
  return !!action.href && actionOptionsCount === 0;
}
