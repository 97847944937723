import {
  ApiLinks,
  ApiResourceAction,
  ApiShopItemClientData,
} from '@/api/types';
import { Guid } from '@/types/utility-types';

/**
 * Looking for shop API Types ?
 * check out `src/api/types/shop.ts`
 */

export type Price = {
  pre_tax: number;
  pre_tax_string: string;
  tax: number;
  tax_string: string;
  post_tax: number;
  post_tax_string: string;
};

export type ShopItemType = 'all' | 'service' | 'addons' | 'oneshot' | 'bundle';

export const ShopItemTypes = [
  'all',
  'service',
  'addons',
  'oneshot',
  'bundle',
] as const;

export const ShopItemStates = ['available', 'purchase', 'upgrade'] as const;

export const ItemStates = ['available'] as const;

export type ShopItem = {
  actions: ApiResourceAction[];
  available: boolean;
  clientData: ApiShopItemClientData;
  description: string;
  links: ApiLinks;
  id: Guid;
  isActive: boolean;
  name: string;
  price: Price;
  state: (typeof ItemStates)[number];
  tagline: string;
  tags: string[];
  type: ShopItemType;
};
