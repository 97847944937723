import React from 'react';

import { AnimatedRoute } from '../animated-route';

const CookiePolicyPage = React.lazy(
  () => import('@/features/cookie-policy/components/cookie-policy-page'),
);

export function CookiePolicyRoute() {
  return (
    <AnimatedRoute animationKey="cookie-policy">
      <CookiePolicyPage />
    </AnimatedRoute>
  );
}
