import { ApiResourceActionOptionsProperty } from '@/api/types';

import { CommonActionPropertyOptions } from '../../../types';
import { ActionPropertyInputDialogView } from './dialog-view';

type ActionPropertyDialogProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyDialog({ data }: ActionPropertyDialogProps) {
  return (
    <ActionPropertyInputDialogView
      triggerLabel={data.label ?? data.title ?? ''}
      description={data.description ?? ''}
    />
  );
}
