/**
 * This utility function allows us to take a route with path params
 * and replace those with the actual values from the params object.
 *
 * @param route - The route with path params e.g. `/tags/:tagId/actions/:actionId`
 * @param params - The object containing the actual values for the path params
 * @returns - The route with the path params replaced with the actual values
 */
export function parseRoute(
  route: string,
  params: Record<string, string>,
): string {
  return Object.entries(params).reduce((acc, [key, value]) => {
    return acc.replace(`:${key}`, value);
  }, route);
}
