import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ShopItemData } from './shop-item-data';
import { ShopItemError } from './shop-item-error';
import { ShopItemView } from './shop-item-view';

/**
 * This component taking a loading prop at the "top" of the component is
 * not normal and a bit of an anti-pattern to the way we should do things!
 * Please don't copy this, it's hopefully temporary.
 */
export function ShopItem({ loading }: { loading?: boolean }) {
  const { t } = useTranslation();

  if (loading) {
    return <ShopItemView loading t={t} />;
  }

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={(p) => <ShopItemError {...p} t={t} />}
        >
          <Suspense fallback={<ShopItemView loading t={t} />}>
            <ShopItemData t={t} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
