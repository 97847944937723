import { Controller, useFormContext } from 'react-hook-form';

import { ApiResourceActionOptionsProperty } from '@/api/types';

import { mapActionOptionsPropertyToRules } from '../../../../../utils/map-action-property-rules';
import { CommonActionPropertyOptions } from '../../../types';
import { ActionPropertyInputEmailView } from './email-view';

type ActionPropertyInputEmailProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyInputEmail({
  data,
  id,
  options,
}: ActionPropertyInputEmailProps) {
  const { errorMessage, required } = options;
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      rules={mapActionOptionsPropertyToRules(data, required)}
      render={({ field }) => (
        <ActionPropertyInputEmailView
          label={data.title}
          error={options.isError}
          innerRef={field.ref}
          // We can't just spread field here due to ref problems
          name={field.name}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
          helperText={errorMessage}
        />
      )}
      control={control}
    />
  );
}
