import { Skeleton, Typography } from '@pebl/ui';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ApiResourceActionColor } from '@/api/types';

export interface SidebarItemData {
  name?: string;
  color?: ApiResourceActionColor;
  icon?: string | ReactNode;
  loading?: boolean;

  className?: string;

  route?: string; // TODO: Confirm what this is used for on the API
  /**
   * Should this link open in a new tab?
   */
  targetBlank?: boolean;

  component?: React.ElementType;
}

export function SideBarItem({
  className,
  component = 'div',
  icon,
  loading,
  name,
  route,
  targetBlank,
}: SidebarItemData) {
  const Component = component;
  return (
    <Component className={clsx('grid min-h-14', className)}>
      {loading ? (
        <div className="flex items-center gap-4 p-2 pl-4">
          <Skeleton variant="small-circle" />
          <Skeleton variant="text" className="w-full" />
        </div>
      ) : (
        <Link
          to={route ?? ''}
          target={targetBlank ? '_blank' : undefined}
          className="flex items-center gap-4 p-2 pl-4"
        >
          <span className="size-6">{icon}</span>
          <Typography variant="body1">{name}</Typography>
        </Link>
      )}
    </Component>
  );
}
