import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ErrorFallback } from '@/components/error-fallback';

import { PaymentData } from './payment-data';
import { PaymentView } from './payment-view';

export default function Payment() {
  const { t } = useTranslation();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={(p) => <ErrorFallback {...p} t={t} />}
        >
          <Suspense fallback={<PaymentView loading t={t} />}>
            <PaymentData t={t} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
