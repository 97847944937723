import { Checkbox, Typography } from '@pebl/ui';

import { ApiResourceActionOptionsPropertyItem } from '@/api/types';
import { AsyncImage } from '@/components/async-image';

export type ActionPropertyMultiselectViewItemProps = {
  isSelected: boolean;
  toggleSelected: () => void;
} & ApiResourceActionOptionsPropertyItem;

export function MultiselectViewItem({
  data,
  descriptions,
  isSelected,
  text,
  thumbnail,
  toggleSelected,
}: ActionPropertyMultiselectViewItemProps) {
  return (
    <div
      className={`
        action-property-multiselect-item flex cursor-pointer rounded-2xl p-4 shadow-display-card
      `}
      onClick={toggleSelected}
    >
      <div className="action-property-multiselect-item-content flex flex-1 flex-col gap-1">
        <div className="action-property-multiselect-item-content-header flex items-center gap-1">
          {thumbnail && !descriptions?.length && (
            <AsyncImage src={thumbnail} alt="thumbnail" className="size-5" />
          )}
          {text && <Typography variant="h5">{text}</Typography>}
        </div>

        <div className="flex gap-2">
          {descriptions && descriptions.length > 0 && (
            <div className="action-property-multiselect-item-description flex flex-col gap-1">
              {descriptions.map((description, index) => (
                <div key={index} className="flex items-center gap-2">
                  {index === 0 && thumbnail && (
                    <AsyncImage
                      src={thumbnail}
                      alt="thumbnail"
                      className="size-5"
                    />
                  )}

                  <Typography
                    key={index}
                    variant={index == 0 ? 'subtitle1' : 'body2'}
                  >
                    {description}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="action-property-multiselect-item-select flex self-center">
        {isSelected && (
          <Checkbox id={data ?? ''} checked={true} onClick={toggleSelected} />
        )}
        {!isSelected && (
          <Checkbox id={data ?? ''} checked={false} onClick={toggleSelected} />
        )}
      </div>
    </div>
  );
}
