import { Button, SyncIcon, Typography, WarningStatusIcon } from '@pebl/ui';
import { FallbackProps } from 'react-error-boundary';

import { TranslationComponentProps } from '@/types/translation';

export function ErrorFallback({
  resetErrorBoundary,
  t,
}: FallbackProps & TranslationComponentProps) {
  return (
    <div className="qng-error-fallback flex h-[90dvh] flex-col items-center justify-center gap-4">
      <WarningStatusIcon className="qng-error-fallback-icon size-20" />
      <Typography variant="h4" className="qng-error-fallback-title">
        {t('common.error.primaryDescription')}
      </Typography>
      <Typography variant="body1" className="qng-error-fallback-description">
        {t('common.error.secondaryDescription')}
      </Typography>
      <Button
        onClick={() => resetErrorBoundary?.()}
        className="qng-error-fallback-button flex h-12 w-3/4 items-center justify-center"
      >
        <div className="qng-error-fallback-button-icon flex items-center gap-1 p-4">
          <SyncIcon className="size-4" /> {t('common.error.reset')}
        </div>
      </Button>
    </div>
  );
}
