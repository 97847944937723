import {
  ApiGetBasketDataResponse,
  AuthorizedApiOptions,
  CancellableApiOptions,
} from '@/api/types';
import { ResourceQueryKeyFn } from '@/types/invalidation';

import { axios } from '../../axios';
import { getCommonHeaders } from '../../utils/headers';

const ENDPOINT = `/shop/basket`;

export const resourceQueryKey: ResourceQueryKeyFn = () => ['shop', 'basket']; // TODO: Do we want this to be 'shop', 'basket'?

export async function getBasket(
  options: CancellableApiOptions & AuthorizedApiOptions = {},
): Promise<ApiGetBasketDataResponse> {
  const { data } = await axios.get(ENDPOINT, {
    signal: options?.signal,
    headers: getCommonHeaders({ authToken: options.authToken }),
  });

  return data;
}
