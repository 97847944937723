import { Suspense } from 'react';

import { useGetRootPrefetch } from '@/common-queries/use-get-root';
import CookiePolicyOverlay from '@/features/cookie-policy/components/cookie-policy-overlay';
import { HeaderBar } from '@/features/navigation/components/header-bar';
import { useSelectQngAuth } from '@/stores/selectors';

import { AnimatedOutlet } from './routing/animated-outlet';

/**
 * Our main layout component for the Guest Website.
 * If we have stuff like a top navbar, sidebar, footer etc then
 * we want to put that in here so it's common between all routes
 * in the Guest Website.
 */
export function Layout() {
  /**
   * I'm not certain this is the place for this long term, but
   * for now we can put our pre-caching of queries here which
   * we know are ALWAYS required, irrespective of the route, so
   * an obvious example is queries related to the sidebar navigation.
   *
   * Since we use Suspense for our queries (and thus useSuspenseQuery)
   * we can't just pre-fetch in the "standard" way and need to use
   * th `usePrefetchQuery` hook to prevent blocking
   * See: https://tanstack.com/query/latest/docs/framework/react/guides/prefetching
   *
   * We may want to use React-Router loaders for more route specific
   * pre-caching
   */
  const { authToken, isAuthTokenAnonymous } = useSelectQngAuth();

  useGetRootPrefetch({ authToken, isAuthTokenAnonymous });

  /**
   * TODO: it would be nice to pre-fetch the shop items here since that api call
   * takes so long, however pre-fetching here doesn't work too nicely with the
   * query persist/hydrate bits (currently). If we pre-fetch here it seems to nullify
   * the fact we have a persisted query and instead of seeing the cached data while refetching
   * we see the loading skeleton state.
   */

  return (
    <>
      <HeaderBar />
      <main className="qng-main-layout-container">
        {/*
          TODO: Re-assess this Suspense, without it we get crashes when clicking
          a link that routes internally.
        */}
        <Suspense fallback={<div />}>
          {/* <AnimatePresence mode="wait">
            <Outlet />
          </AnimatePresence> */}
          <AnimatedOutlet />
        </Suspense>
      </main>
      <CookiePolicyOverlay />
    </>
  );
}
