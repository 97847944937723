import { Controller, useFormContext } from 'react-hook-form';

import { ApiResourceActionOptionsProperty } from '@/api/types';

import { CommonActionPropertyOptions } from '../../types';
import { ActionPropertyQuantityStepperView } from './quantity-stepper-view';

type ActionPropertyQuantityStepperProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyQuantityStepper({
  data,
  id,
  options,
}: ActionPropertyQuantityStepperProps) {
  const { errorMessage, required } = options;
  const { control } = useFormContext();

  const { default: defaultValue, maximum, minimum } = data;

  let narrowDefault = undefined;

  if (typeof defaultValue === 'number') {
    narrowDefault = defaultValue;
  } else if (typeof defaultValue === 'string') {
    narrowDefault = parseInt(defaultValue, 10);

    if (isNaN(narrowDefault)) {
      narrowDefault = undefined;
    }
  }

  const narrowMinimum =
    typeof minimum === 'number'
      ? minimum
      : typeof minimum === 'string'
        ? parseInt(minimum, 10)
        : undefined;

  const narrowMaximum =
    typeof maximum === 'number'
      ? maximum
      : typeof maximum === 'string'
        ? parseInt(maximum, 10)
        : undefined;

  return (
    <Controller
      name={id}
      rules={{
        required: required,
      }}
      defaultValue={narrowDefault}
      render={({ field }) => (
        <ActionPropertyQuantityStepperView
          label={data.title}
          error={options.isError}
          innerRef={field.ref}
          // We can't just spread field here due to ref problems
          name={field.name}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
          helperText={errorMessage ?? data.description}
          initialValue={narrowDefault}
          min={narrowMinimum}
          max={narrowMaximum}
        />
      )}
      control={control}
    />
  );
}
