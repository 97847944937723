import { ApiResourceAction } from '@/api/types';
import { mapApiActionMethodToHttpMethod } from '@/api/utils/map-action-method';
import { setAuthToken } from '@/stores/qng-data-store';
import { useSelectQngAuth } from '@/stores/selectors';

import {
  useSubmitActionMutation,
  useSubmitActionMutationNew,
} from '../api/use-submit-action-mutation';

type UseActionHookProps = {
  /**
   * The Action to create an execute function for.
   */
  action?: ApiResourceAction;

  /**
   * Whether to prevent any redirect handling the API may include in the response.
   */
  preventRedirect?: boolean;
};

type UseActionHookResponse = {
  isPending: boolean;
  executeAction: ReturnType<typeof useSubmitActionMutation>['mutateAsync'];
};

/**
 * WARNING: This is an internal hook, please do not use this hook directly in your components.
 */
export function useAction({
  action,
  preventRedirect,
}: UseActionHookProps): UseActionHookResponse {
  const { authToken } = useSelectQngAuth();
  const targetUrl = action?.href ?? '';
  const submitMethod = mapApiActionMethodToHttpMethod(action?.method ?? 'get');

  const { isPending, mutateAsync: mutate } = useSubmitActionMutation({
    authToken,
    url: targetUrl,
    method: submitMethod,
    preventRedirect: preventRedirect,
    beforeRedirect: () => {
      // TODO: Re-assess how we handle this after our talks about the API not controlling UI sign in/out behavior.
      if (action?.type === 'signout') {
        setAuthToken({
          authToken: undefined,
          isTokenAnonymous: false,
        });
      }
    },
  });

  return {
    isPending,
    executeAction: mutate,
  };
}

type UseUnknownActionHookResponse = {
  isPending: boolean;
  executeAction: ReturnType<typeof useSubmitActionMutationNew>['mutateAsync'];
  error: ReturnType<typeof useSubmitActionMutationNew>['error'];
};

/**
 * WARNING: This is an internal hook, please do not use this hook directly in your components.
 *
 * I don't know if I like the naming of this, but it does describe what it's
 * doing mostly. The useAction above returns the executeAction function for a
 * specific known action, whereas this hook returns an executeAction function
 * that itself takes in an action+config as variables to the mutate/execute call.
 *
 * In practice this means we can use this hook in places where we don't yet know
 * or have the action OR the action may change (I.E. Chained Actions).
 *
 * Since these hooks are ONLY intended to be used within the context of the actions
 * and not externally used by others this is probably a fine name for now.
 */
export function useUnknownAction(): UseUnknownActionHookResponse {
  const { authToken } = useSelectQngAuth();

  const {
    error,
    isPending,
    mutateAsync: mutate,
  } = useSubmitActionMutationNew({
    authToken,
  });

  return {
    isPending,
    executeAction: mutate,
    error,
  };
}
