import { Button } from '@pebl/ui';
import { FallbackProps } from 'react-error-boundary';

export function ActionFormError({ resetErrorBoundary }: FallbackProps) {
  return (
    <div className="grid grid-rows-2">
      <p>There was an error getting the data!</p>
      <Button onClick={() => resetErrorBoundary()}>Try again</Button>
    </div>
  );
}
