import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React, { forwardRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ApiResourceAction } from '@/api/types';

import { ActionFormCallbacks, ActionFormRef } from '../../types';
import { ActionFormData } from './action-form-data';
import { ActionFormError } from './action-form-error';
import { ActionFormView } from './action-form-view';

export type ActionFormProps = {
  /**
   * The action to render the form for.
   */
  action: ApiResourceAction;

  /**
   * An optional parameter that can be set to prevent any redirection
   * handling the API may include in the response from being handled
   * higher up the stack (up in the API level).
   * Generally we wouldn't want to prevent this, but there may be
   * cases where we need to.
   */
  preventRedirect?: boolean;

  /**
   * If `true`, the title of the action will not be shown in the form.
   * @default false - The title will be displayed.
   */
  hideTitle?: boolean;

  /**
   * Optionally provide class names to add to the ActionForm container.
   */
  className?: string;
} & ActionFormCallbacks;

export const ActionForm = forwardRef<ActionFormRef, ActionFormProps>(
  function ActionForm(
    {
      action,
      className,
      hideTitle,
      onActionCancelled,
      onActionChained,
      onActionError,
      onActionSuccess,
      preventRedirect,
    }: ActionFormProps,
    ref,
  ) {
    const { t } = useTranslation();

    return (
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary onReset={reset} fallbackRender={ActionFormError}>
            <React.Suspense
              fallback={<ActionFormView t={t} className={className} loading />}
            >
              <ActionFormData
                ref={ref}
                action={action}
                preventRedirect={preventRedirect}
                hideTitle={hideTitle}
                onActionSuccess={onActionSuccess}
                onActionError={onActionError}
                onActionCancelled={onActionCancelled}
                onActionChained={onActionChained}
                className={className}
                t={t}
              />
            </React.Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    );
  },
);

export default ActionForm;
