import { v5 as uuidv5 } from 'uuid';

import { ApiResourceAction } from '@/api/types';

const QNG_HASH_NAMESPACE = '711c0e5c-b87c-486a-9c42-d765845beefb';

/**
 * This is a temporary function to hash action objects to a unique key
 * for us to use in React iteration, until the API provides a unique key.
 *
 * @param action - The action object to hash
 * @returns The hashed key
 */
export function hashAction(action: ApiResourceAction): string {
  const dataToHash = JSON.stringify(action ?? '');

  const hashGuid = uuidv5(dataToHash, QNG_HASH_NAMESPACE);
  return hashGuid;
}
