import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApiResourceActionOptionsProperty } from '@/api/types';

import { mapActionOptionsPropertyToRules } from '../../../../../utils/map-action-property-rules';
import { CommonActionPropertyOptions } from '../../../types';
import { ActionPropertyInputBarcodeView } from './barcode-view';

type ActionPropertyInputBarcodeProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyInputBarcode({
  data,
  id,
  options,
}: ActionPropertyInputBarcodeProps) {
  const { errorMessage, required } = options;
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={id}
      rules={mapActionOptionsPropertyToRules(data, required)}
      render={({ field }) => (
        <ActionPropertyInputBarcodeView
          label={data.title}
          error={options.isError}
          // We can't just spread field here due to ref problems
          innerRef={field.ref}
          name={field.name}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
          helperText={errorMessage}
          dialogCloseButtonText={t('common.dialog.close_button')}
        />
      )}
      control={control}
    />
  );
}
