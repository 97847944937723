import { ApiResourceAction } from '@/api/types';
import log from '@/utils/logging';

/**
 * Given a preventRedirect option which could be a boolean or a regular expression,
 * return a boolean value indicating whether redirection should be prevented.
 *
 * Note: This is extracted from {@link ActionList} so it can be tested easier but also
 *       so we can wrap it with try/catch to ensure it never crashes higher up, without
 *       needing to add extra mess in the ActionList.
 *
 * @param value The preventRedirect option to evaluate.
 * @param action The action that we wish to prevent potential redirection after.
 *               Only used if value is a RegExp to test against the action type.
 * @returns Whether redirection should be prevented.
 */
export function mapPreventRedirect(
  value: boolean | RegExp | undefined,
  action: ApiResourceAction,
): boolean {
  try {
    return value === true
      ? true
      : value instanceof RegExp
        ? value.test(action.type)
        : false;
  } catch (err) {
    log.error('Error evaluating preventRedirect option', err);
    return false;
  }
}
