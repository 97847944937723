import React from 'react';

import { ApiResourceActionOptionsProperty } from '@/api/types';

import { ActionPropertyCheckbox } from '../components/action-form-builder/action-properties/checkbox';
import { ActionPropertyLanguageList } from '../components/action-form-builder/action-properties/language-list';
import { ActionPropertyMultiselect } from '../components/action-form-builder/action-properties/multiselect';
import { ActionPropertyQuantityStepper } from '../components/action-form-builder/action-properties/quantity-stepper';
import { ActionPropertySelect } from '../components/action-form-builder/action-properties/select';
import { ActionPropertyInputBarcode } from '../components/action-form-builder/action-properties/string/barcode';
import { ActionPropertyDialog } from '../components/action-form-builder/action-properties/string/dialog';
import { ActionPropertyInputEmail } from '../components/action-form-builder/action-properties/string/email';
import { ActionPropertyInputPassword } from '../components/action-form-builder/action-properties/string/password';
import { ActionPropertyInputPhoneNumber } from '../components/action-form-builder/action-properties/string/phone-number';
import { ActionPropertyInputText } from '../components/action-form-builder/action-properties/string/text';
import { CommonActionPropertyOptions } from '../components/action-form-builder/types';

/**
 * Map action options by the 'type' property to the correct form component.
 * This is the core of how the we construct action forms dynamically, based
 * on the schema of properties & options for each action.
 * Each Property has a type + format which together determine the form component
 * we should use to render the property in the form.
 *
 * @param id - The ID of the action option
 * @param data - The action option data
 * @param options - Common options for action properties
 * @returns - The form component to render
 */
export function mapActionOptionsToComponent(
  id: string,
  data: ApiResourceActionOptionsProperty,
  options: CommonActionPropertyOptions,
): React.ReactNode {
  switch (
    data.type // To add new types see /src/api/types/actions.ts - ApiResourceActionOptionsPropertyType
  ) {
    case 'string':
      return mapStringActionOptionsToComponent(id, data, options);
    case 'boolean':
      return mapBooleanActionOptionsToComponent(id, data, options);
    case 'integer':
      return mapNumberActionOptionsToComponent(id, data, options);
    case 'object':
      return mapObjectActionOptionsToComponent(id, data, options);
    default:
      return false;
  }
}

/**
 * Map action options where type === 'string', based on the 'format' property
 * to the correct form component.
 *
 * @param id - The ID of the action option
 * @param data - The action option data
 * @param options - Common options for action properties
 * @returns - The form component to render
 */
function mapStringActionOptionsToComponent(
  id: string,
  data: ApiResourceActionOptionsProperty,
  options: CommonActionPropertyOptions,
): React.ReactNode {
  switch (
    data.format // To add new types see /src/api/types/actions.ts - ApiResourceActionOptionsPropertyFormat
  ) {
    case 'field':
      return <ActionPropertyInputText id={id} data={data} options={options} />;
    case 'barcode':
      return (
        <ActionPropertyInputBarcode id={id} data={data} options={options} />
      );
    case 'email':
      return <ActionPropertyInputEmail id={id} data={data} options={options} />;
    case 'password':
      return (
        <ActionPropertyInputPassword id={id} data={data} options={options} />
      );
    case 'dialog':
      return <ActionPropertyDialog id={id} data={data} options={options} />;
    default:
      return false;
  }
}

/**
 * Map action options where type === 'boolean' to the correct form component.
 *
 * @param id - The ID of the action option
 * @param data - The action option data
 * @param options - Common options for action properties
 * @returns - The form component to render
 */
function mapBooleanActionOptionsToComponent(
  id: string,
  data: ApiResourceActionOptionsProperty,
  options: CommonActionPropertyOptions,
): React.ReactNode {
  switch (data.format) {
    case 'checkbox':
      return <ActionPropertyCheckbox id={id} data={data} options={options} />;
    default:
      return false;
  }
}

/**
 * Map action options where type === 'object' to the correct form component.
 *
 * @param id - The ID of the action option
 * @param data - The action option data
 * @param options - Common options for action properties
 * @returns - The form component to render
 */
function mapObjectActionOptionsToComponent(
  id: string,
  data: ApiResourceActionOptionsProperty,
  options: CommonActionPropertyOptions,
): React.ReactNode {
  switch (data.format) {
    case 'phone':
      return (
        <ActionPropertyInputPhoneNumber id={id} data={data} options={options} />
      );
    case 'select':
      return <ActionPropertySelect id={id} data={data} options={options} />;
    case 'languagelist':
      return (
        <ActionPropertyLanguageList id={id} data={data} options={options} />
      );
    case 'multiselect':
      return (
        <ActionPropertyMultiselect id={id} data={data} options={options} />
      );
    default:
      return false;
  }
}

/**
 * Map action options where type === 'integer' to the correct form component.
 *
 * @param id - The ID of the action option
 * @param data - The action option data
 * @param options - Common options for action properties
 * @returns - The form component to render
 */
function mapNumberActionOptionsToComponent(
  id: string,
  data: ApiResourceActionOptionsProperty,
  options: CommonActionPropertyOptions,
): React.ReactNode {
  switch (data.format) {
    default:
      return (
        <ActionPropertyQuantityStepper id={id} data={data} options={options} />
      );
  }
}
