import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { SiteBannerData } from './site-banner-data';
import { SiteBannerError } from './site-banner-error';
import { SiteBannerView } from './site-banner-view';

/**
 * The site banner component is responsible for displaying
 * error and informational notifications to the user.
 *
 * It uses the `useQngDataStore` to get the notifications
 * and then displays the latest one.
 *
 * Error notifications have priority to be shown over informational
 * notifications.
 *
 * If there are no notifications then nothing is displayed.
 */
export default function SiteBanner() {
  const { t } = useTranslation();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={(p) => <SiteBannerError {...p} t={t} />}
        >
          <Suspense fallback={<SiteBannerView loading t={t} />}>
            <SiteBannerData t={t} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
