import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  Typography,
} from '@pebl/ui';
import parse from 'html-react-parser';

import { CommonActionPropertyViewProps } from '../../../types';

// TODO: This is currently broken and VERY long text I.E. Terms and conditions doesn't scroll
export function ActionPropertyInputDialogView({
  description,
  disabled,
  triggerLabel,
}: {
  error?: boolean;
  triggerLabel?: string;
  description?: string;
} & Pick<CommonActionPropertyViewProps<string>, 'disabled'>) {
  return (
    <Dialog>
      <DialogTrigger
        disabled={disabled}
        className="qng-action-property-input-dialog-trigger text-left"
      >
        <Typography variant="body2">
          {triggerLabel && parse(triggerLabel)}
        </Typography>
      </DialogTrigger>
      <DialogContent className="qng-action-property-input-dialog-content">
        {/* DialogTitle required, even though it's empty to avoid errors */}
        <DialogTitle></DialogTitle>
        <DialogDescription className="overflow-y-auto" asChild>
          <div>{description && parse(description)}</div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
