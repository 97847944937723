import { Controller, useFormContext } from 'react-hook-form';

import { ApiResourceActionOptionsProperty } from '@/api/types';

import { mapActionOptionsPropertyToRules } from '../../../../utils/map-action-property-rules';
import { CommonActionPropertyOptions } from '../../types';
import {
  ActionPropertyLanguageListItemData,
  ActionPropertyLanguageListView,
} from './language-list-view';

type ActionPropertyLanguageListProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyLanguageList({
  data,
  id,
  options,
}: ActionPropertyLanguageListProps) {
  const { errorMessage, required } = options;
  const { control } = useFormContext();

  const items = (data.enum ?? [])
    .map((item) => ({
      value: item.data,
      displayText: item.text,
    }))
    .filter(
      (x) => x.value !== undefined,
    ) as ActionPropertyLanguageListItemData[];

  const defaultValue = (data.enum ?? []).find((item) => item.default)?.data;

  return (
    <Controller
      name={id}
      rules={mapActionOptionsPropertyToRules(data, required)}
      render={({ field }) => (
        <ActionPropertyLanguageListView
          items={items}
          label={data.title}
          error={options.isError}
          // We can't just spread field here due to ref problems
          innerRef={field.ref}
          name={field.name}
          defaultValue={defaultValue}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
          helperText={errorMessage}
        />
      )}
      control={control}
    />
  );
}
