import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { SideBarData } from './side-bar-data';
import { SideBarError } from './side-bar-error';
import { SideBarView } from './side-bar-view';

export function Sidebar() {
  const { t } = useTranslation();
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={(p) => <SideBarError {...p} t={t} />}
        >
          <Suspense fallback={<SideBarView loading t={t} />}>
            <SideBarData t={t} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
