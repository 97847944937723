import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { Sidebar } from '../side-bar';
import { HeaderBarData } from './header-bar-data';
import { HeaderBarError } from './header-bar-error';
import { HeaderBarView } from './header-bar-view';

export function HeaderBar() {
  const { t } = useTranslation();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={(p) => <HeaderBarError {...p} t={t} />}
        >
          <Suspense
            fallback={
              <HeaderBarView loading t={t}>
                <Sidebar />
              </HeaderBarView>
            }
          >
            <HeaderBarData t={t} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
