/**
 * The QNG cookie name inside browser's Cookies storage.
 */
export const QNG_COOKIE_NAME = 'qng-cookie-policy';

/**
 * The relative max age of the cookie from when the client receives it in seconds
 * 31536000 equal to 1 year
 */
export const QNG_COOKIE_MAX_AGE = 31536000;
