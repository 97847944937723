import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useSelectQngAuth } from '@/stores/selectors';
import { TranslationComponentProps } from '@/types/translation';
import log from '@/utils/logging';

import { useGetPayment } from '../../api/use-get-payment';
import { PaymentView } from './payment-view';

type PaymentDataProps = {} & TranslationComponentProps;

export function PaymentData({ t }: PaymentDataProps) {
  const queryClient = useQueryClient();
  const { authToken } = useSelectQngAuth();
  const { actions, clientData } = useGetPayment({ authToken });

  useEffect(() => {
    /*
     * This is a temporary solution to avoid showing outdated data after
     * completing a payment
     * QNG-1022
     * Normally we should rely on the API defined invalidations, but after
     * a purchase it's trickier so we just invalidate everything for now.
     */
    log.debug('💵 Resetting all queries after payment');
    queryClient.resetQueries(undefined, { cancelRefetch: false });
    // TODO: In QNG-1022 I've detailed this more

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaymentView
      t={t}
      name={clientData.name}
      description={clientData.description}
      actions={actions}
    />
  );
}
