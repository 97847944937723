import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import useConfigThemePath from '@/hooks/use-config-theme-path';
import log from '@/utils/logging';

type DynamicThemingProps = {
  prefix?: string;
  overrideTheme?: string;
  enableVersionSuffix?: boolean;
  localStorageKey?: string;
  defaultThemeFile?: string;
};

/**
 * This implements our runtime theming system.
 *
 * The general idea is that the site can determine what 'theme' it should
 * be using at runtime from a few sources (in order of priority):
 * - Via a query parameter (not implemented yet)
 * - Via a local storage value (mostly useful for developers to force a theme)
 * - Via the `defaultTheme` deploy config value set in the versions repository.
 *
 * I'm still considering if there is scope for a "default" behavior where a request
 * to CloudFront for /theme.css would actually map via the deploy `defaultTheme` to
 * the correct theme file as a default fallback behavior. This would require more
 * extensive changes to the CF functions though which I'm reluctant to do right now.
 *
 * This component loads the theme CSS data with a fetch call and then uses
 * helmet to inject that data into the page header.
 *
 * Currently on a fresh load (with no theme cached) the site will load with the
 * default in-built theme and then when the specified theme is loaded (if there is one)
 * it will be applied, causing the site to re-render and possibly flash/look glitchy.
 * As a work around we could use the `isLoading` state to prevent the rest of the site
 * rendering until the custom theme is loaded, however this *will* cause the initial
 * load of the site to suffer! so for now I'm avoiding that.
 */
export function DynamicTheming({
  defaultThemeFile = 'theme.css',
  enableVersionSuffix = false,
  localStorageKey,
  overrideTheme,
  prefix = '',
}: DynamicThemingProps) {
  const theme = useConfigThemePath({ localStorageKey, overrideTheme });

  const suffix = enableVersionSuffix
    ? `?v=${Math.floor(Math.random() * 999999)}`
    : '';
  const stylesheetUri = !theme
    ? undefined
    : `${prefix}${theme}${defaultThemeFile}${suffix}`;

  const [cssData, setCssData] = useState<string | undefined>(undefined);
  const [, setIsLoading] = useState<boolean>(!!stylesheetUri);

  const fetchTheme = useCallback(async (uri: string) => {
    return await fetch(uri)
      .then((res) => res.text())
      .catch((e) => {
        log.error(
          `🎨 DynamicTheming: Error loading dynamic theme "${uri}": `,
          e,
        );
      });
  }, []);

  const stylesheetUriRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    log.debug('🎨 DynamicTheming: theme changed', stylesheetUri);

    if (stylesheetUri === undefined && stylesheetUriRef.current === undefined) {
      // Early out if there is no stylesheetUri and it was never set before.
      return;
    }

    /*
     * If stylesheetUri is undefined (no theme specified) and
     * previously it WAS set to a value, then we need to clear
     * the CSS data so that the theme is removed.
     */
    if (stylesheetUri === undefined && stylesheetUriRef.current !== undefined) {
      log.debug('🎨 DynamicTheming: theme unset, clearing CSS data');
      setCssData(undefined);
      setIsLoading(false);
      return;
    }

    // If there is a stylesheetUri, is it different from the last one?
    if (
      stylesheetUri !== undefined &&
      stylesheetUri !== stylesheetUriRef.current
    ) {
      log.debug('🎨 DynamicTheming: loading CSS stylesheet', stylesheetUri);
      fetchTheme(stylesheetUri)
        .then((data) => {
          if (data) {
            setCssData(data);
          }
        })
        .finally(() => setIsLoading(false));
    }

    // Update the ref so we can compare it next time.
    stylesheetUriRef.current = stylesheetUri;

    // Return a cleanup function just for debugging purposes.
    return () => {
      log.debug('🎨 DynamicTheming: cleanup');
    };
  }, [fetchTheme, stylesheetUri]);

  /*
   * We could use isLoading and make this component actually
   * wrap the rest of the site and then that way we could prevent
   * rendering the rest of the site until the theme is loaded,
   * but that would cause much worse performance!
   * Maybe we consider just setting a "themeLoading" global state
   * value in Zustand which can be used more selectively elsewhere
   * while still allowing other components to load and begin fetching data.
   */

  return (
    <Helmet async={false} defer={false} encodeSpecialCharacters={false}>
      {/*
        We can't use (it seems) onLoad on <link> elements here, so we
        can't get a callback when the load is complete to un-suspense.
        As such I'm implementing a different technique using fetch instead of
        letting the browser load the stylesheet.
        We'll still get caching benefits in theory.
        */}
      {/* {stylesheetUri && (
          <link
            rel="stylesheet"
            type="text/css"
            href={stylesheetUri}
            // onLoad={"window.onThemeLoaded()" as any}
          />
        )} */}
      {cssData && <style id="qng-custom-theme">{cssData}</style>}
    </Helmet>
  );
}
