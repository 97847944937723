import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import log from '@/utils/logging';
import { stringToNumber } from '@/utils/string-utils';

export const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

const defaultQueryStaleTime = stringToNumber(
  import.meta.env.VITE_DEFAULT_QUERY_STALE_TIME,
);

const defaultQueryGarbageCollectionTime = stringToNumber(
  import.meta.env.VITE_DEFAULT_QUERY_GC_TIME,
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true, // This is the default
      refetchOnReconnect: true, // This is the default
      refetchOnWindowFocus: true, // This is the default
      staleTime: defaultQueryStaleTime ?? 60000, // 1 minute
      /*
       * Important! - Our GC time must be >= to the maxAge value of our query persister
       * in - src\entrypoints\guest-website\index.tsx
       */
      gcTime: defaultQueryGarbageCollectionTime ?? 600000, // 10 minutes
      retry: (failureCount, error) => {
        if (!error) {
          return failureCount < 3;
        }

        if (isAxiosError(error)) {
          const responseStatus = error.response?.status;

          // Don't retry 4xx errors
          if (
            responseStatus !== undefined &&
            responseStatus >= 400 &&
            responseStatus < 500
          ) {
            return false;
          }
        }

        return failureCount < 3;
      },
      refetchInterval: (query) => {
        /*
         * This is wrapped in a try/catch to ensure if anything weird happens it shouldn't
         * completely destroy the site.
         */
        try {
          /*
           * NOTE: Future consideration, we could alter this behaviour based on the
           * query key if needed by accessing query.queryKey
           */
          if (query?.state?.status === 'success') {
            const responseData = query?.state?.data ?? {};
            if (
              responseData &&
              typeof responseData === 'object' &&
              'expires_in_secs' in responseData &&
              typeof responseData.expires_in_secs === 'number'
            ) {
              const expiresInMillis = responseData.expires_in_secs * 1000;

              // ⚠️ Careful enabling this logging, it's quite verbose!
              /*
               * log.debug(
               *   "⏰ API response has 'expires_in_secs' set, modifying refetch interval to:",
               *   expiresInMillis,
               * );
               */

              return expiresInMillis;
            }
          }

          const defaultRefetchIntervalMilliseconds = stringToNumber(
            import.meta.env.VITE_DEFAULT_REFETCH_INTERVAL_MILLISECONDS,
          );

          return defaultRefetchIntervalMilliseconds ?? 60000;
        } catch (error) {
          log.error('⏰ Error in refetchInterval:', error);
          return 60000;
        }
      },
    },
    mutations: {},
  },
});
