import {
  Badge,
  CartIcon,
  LoadingIndicator,
  MenuIcon,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  Typography,
} from '@pebl/ui';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { Divider } from '@/components/divider';
import SiteBanner from '@/components/site-banner';
import RoutePathConstants from '@/entrypoints/guest-website/routing/route-path-constants';
import { CommonViewProps } from '@/types/core';
import { TranslationComponentProps } from '@/types/translation';

type HeaderBarViewProps = PropsWithChildren<{
  title?: string;
  sidebarTitle?: string;
  basketItemCount?: number;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  showLoadingIndicator?: boolean;
}> &
  TranslationComponentProps &
  CommonViewProps;

export function HeaderBarView({
  basketItemCount,
  children,
  loading,
  open,
  setOpen,
  showLoadingIndicator = false,
  sidebarTitle,
  t,
  title,
}: HeaderBarViewProps) {
  /**
   * While in development it's useful sometimes to have access to the
   * developer test page '/developer'.
   * To make this easier, this allows us to click the name/welcome in
   * the sidebar header to navigate there while in development mode.
   * This now works on all deployments as well as locally, so you can
   * test with it once deployed if necessary. The only time it's NOT included
   * / built in is for a "real" release I.E. a versioned release by release please.
   */
  const isRelease = import.meta.env.VITE_IS_RELEASE;

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <div className="qng-header-bar-outer sticky top-0 z-10">
        <div className="qng-header-bar-inner relative z-10 grow">
          <div
            className={`
              qng-header-bar static flex h-16 items-center border-b border-g4 bg-primary
              text-primary-foreground shadow-none
            `}
          >
            <SheetTrigger className="p-4">
              <MenuIcon />
            </SheetTrigger>
            <div className="flex flex-1 items-center p-4 pl-0">
              <Typography className="line-clamp-1 h-7 grow" variant="h4">
                <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  {title && parse(title)}
                </motion.span>
              </Typography>
              {!!basketItemCount && basketItemCount > 0 && (
                <Link
                  title="Go to basket"
                  to={RoutePathConstants.BASKET.link()}
                  className={`h-5`}
                >
                  <span className="inline-flex">
                    <CartIcon outlined />
                    <Badge
                      className="-ml-2.5 -mt-1.5 bg-white text-black"
                      value={basketItemCount}
                    />
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
        {showLoadingIndicator && (
          <LoadingIndicator className="absolute h-[2px] text-global-loading" />
        )}
        {!loading && <SiteBanner />}
      </div>
      <SheetContent side="left" className="flex w-[250px] flex-col gap-0 p-0">
        <SheetDescription />
        <SheetHeader className="p-4">
          {!isRelease ? (
            <Link to="/developer">
              <SheetTitle
                className="overflow-hidden text-left"
                data-trans-key="sidebar.welcome_anonymous"
              >
                {sidebarTitle ?? t('sidebar.welcome_anonymous')}
              </SheetTitle>
            </Link>
          ) : (
            <SheetTitle className="overflow-hidden text-left">
              {sidebarTitle ?? t('sidebar.welcome_anonymous')}
            </SheetTitle>
          )}
        </SheetHeader>
        <Divider />
        {children}
      </SheetContent>
    </Sheet>
  );
}
