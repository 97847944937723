import { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';

import { ApiResourceActionOptionsProperty } from '@/api/types';
import { isNumber } from '@/utils/typeguards';

/**
 * Map an API Action Options Property to a set of rules that can
 * be used by react-hook-form.
 *
 * @param prop - The API Action Options Property to map
 * @param required - Whether the property is required or not
 * @returns - The rules to directly apply to a react-hook-form Controller
 */
export function mapActionOptionsPropertyToRules(
  prop: ApiResourceActionOptionsProperty,
  required: boolean = false,
): Omit<
  RegisterOptions<FieldValues, FieldPath<FieldValues>>,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
> {
  return {
    required: required,
    min: isNumber(prop.min) ? prop.min : undefined,
    max: isNumber(prop.max) ? prop.max : undefined,
    maxLength: prop.maxLength,
    minLength: prop.minLength,
    pattern: prop.pattern ? new RegExp(prop.pattern) : undefined, // WARNING This could throw if regex is invalid
  };
}
