import { Controller, useFormContext } from 'react-hook-form';

import { ApiResourceActionOptionsProperty } from '@/api/types';

import { CommonActionPropertyOptions } from '../../types';
import { ActionPropertyCheckboxView } from './checkbox-view';

type ActionPropertyCheckboxProps = {
  id: string;
  data: ApiResourceActionOptionsProperty;
  options: CommonActionPropertyOptions;
};

export function ActionPropertyCheckbox({
  data,
  id,
  options,
}: ActionPropertyCheckboxProps) {
  const { required } = options;
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <ActionPropertyCheckboxView
          label={data.title}
          error={options.isError}
          innerRef={field.ref}
          // We can't just spread field here due to ref problems
          name={field.name}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onBlur={field.onBlur}
        />
      )}
    />
  );
}
